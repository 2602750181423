var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      [
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("预定人")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.detailsObj.userName || "--")),
          ]),
        ]),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("手机号码")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.detailsObj.userPhone || "--")),
          ]),
        ]),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("预定日期")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.detailsObj.destinedDate || "--")),
          ]),
        ]),
        _c(
          "li",
          { staticClass: "wrap" },
          [
            _c("p", { staticClass: "title" }, [_vm._v("预定时段")]),
            _vm._l(_vm.detailsObj.goodsList, function (item) {
              return _c(
                "p",
                { key: item.id, staticClass: "content" },
                [
                  _c("span", [_vm._v(_vm._s(" " + item.goodsName || "--"))]),
                  _vm._l(item.goodsSlotList, function (i, index) {
                    return _c("span", { key: index }, [
                      _vm._v(
                        _vm._s(" " + i.destinedSlot) +
                          _vm._s(_vm.getSemicolon(item.goodsSlotList, index))
                      ),
                    ])
                  }),
                ],
                2
              )
            }),
          ],
          2
        ),
        _c(
          "li",
          { staticClass: "wrap" },
          [
            _c("p", { staticClass: "title" }, [_vm._v("场地名称")]),
            _vm._l(_vm.detailsObj.goodsList, function (item, index) {
              return _c("p", { key: index, staticClass: "content" }, [
                _vm._v(_vm._s(item.goodsName || "--") + "\n            "),
              ])
            }),
          ],
          2
        ),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("费用")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(
              _vm._s(
                _vm.detailsObj.totalMoney || _vm.detailsObj.totalMoney === 0
                  ? _vm.detailsObj.totalMoney
                  : "--"
              ) + "元"
            ),
          ]),
        ]),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("月结金额")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(
              _vm._s(
                _vm.detailsObj.monthPayMoney ||
                  _vm.detailsObj.monthPayMoney === 0
                  ? _vm.detailsObj.monthPayMoney
                  : "--"
              ) + "元"
            ),
          ]),
        ]),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("月结公司")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.detailsObj.monthPayCompany || "--")),
          ]),
        ]),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("状态")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.detailsObj.destinedStatusStr || "--")),
          ]),
        ]),
        _vm.detailsObj.destinedRoad === "wx"
          ? [
              _c("li", { staticClass: "wrap" }, [
                _c("p", { staticClass: "title" }, [_vm._v("微信支付交易单号")]),
                _c("p", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.detailsObj.transactionId || "--")),
                ]),
              ]),
              _c("li", { staticClass: "wrap" }, [
                _c("p", { staticClass: "title" }, [_vm._v("微信支付服务单号")]),
                _c("p", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.detailsObj.destinedCode || "--")),
                ]),
              ]),
              _c("li", { staticClass: "wrap" }, [
                _c("p", { staticClass: "title" }, [_vm._v("微信支付时间")]),
                _c("p", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.detailsObj.payDate || "--")),
                ]),
              ]),
            ]
          : _vm._e(),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("是否开票")]),
          _c(
            "p",
            { staticClass: "content" },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce66", disabled: "" },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          ),
        ]),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("发票编号")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.detailsObj.invoiceBillNo || "--")),
          ]),
        ]),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("开票时间")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(
              _vm._s(
                _vm._f("dateFormat")(
                  _vm.detailsObj.invoiceTime,
                  "YYYY-MM-DD HH:mm:ss"
                ) || "--"
              )
            ),
          ]),
        ]),
        _c("li", { staticClass: "wrap" }, [
          _c("p", { staticClass: "title" }, [_vm._v("预定操作人")]),
          _c("p", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.detailsObj.createdName || "--")),
          ]),
        ]),
        _vm.detailsObj.destinedStatusStr === "已取消"
          ? [
              _c("li", { staticClass: "wrap" }, [
                _c("p", { staticClass: "title" }, [_vm._v("取消预定操作人")]),
                _c("p", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.detailsObj.closeUserName || "--")),
                ]),
              ]),
              _c("li", { staticClass: "wrap" }, [
                _c("p", { staticClass: "title" }, [_vm._v("取消预定时间")]),
                _c("p", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.detailsObj.closeDate || "--")),
                ]),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }