import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**
 * 体育场 - 场地日历 - 获取日历时间段
 * @param params
 */
export function getScheduleList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/siteCalendar',
        method: 'post',
        data: params,
    });
}

/**
 * 体育场 - 场地日历 - 获取日历场地列表
 * @param params
 */
export function getPlaceList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/getCalendarSiteList',
        method: 'get',
        params,
    });
}

/**
 * 体育场 - 场地预定
 * @param params
 */
export function addPredetermine(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/web/create',
        method: 'post',
        data: params,
    });
}
/**
 * 体育场 - 详情
 * @param params
 */
export function getCalendarDetails(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/getCalendarDetails',
        method: 'get',
        params,
    });
}
/**
 * 体育场 - 取消订单
 * @param params
 */
export function removeOrder(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/web/cancelOrder',
        method: 'get',
        params,
    });
}
