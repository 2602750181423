<template>
  <div>
    <el-form :model="ruleForm" :label-position="labelPosition" :rules="rules" ref="ruleForm" label-width="100px"
      class="demo-ruleForm">
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="预定人" prop="userName">
            <el-input v-model="ruleForm.userName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="手机号码" prop="userPhone">
            <el-input v-model="ruleForm.userPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="预定类型" prop="orderType">
            <el-radio-group v-model="ruleForm.orderType" size="medium">
              <el-radio border label="basketball" @change="handelTimeList">篮球场</el-radio>
              <el-radio border label="tennis" @change="handelTimeList">网球场</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="选择时间" prop="destinedDate">
            <!-- <el-select placeholder="请选择预约时间" v-model="ruleForm.destinedDate" @change="handelTimeList">
              <el-option v-for="(item, index) in ruleForm.goodsTimeList" :key="index" :label="item"
                :value="item"></el-option>
            </el-select> -->
            <el-date-picker style="width: 19.375rem;" v-model="ruleForm.destinedDate" type="date" placeholder="选择日期" @change="handelTimeList" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="选择场地" prop="siteType">
            <div class="block">
              <el-cascader placeholder="请选择场地" :props="{ multiple: true }" :options="options" filterable
                v-model="ruleForm.siteType" :key="keyValue" @change="changeSiteType">
              </el-cascader>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="人工收费金额" prop="totalMoney" class="ChargeRemarks">
            <el-input v-model="ruleForm.totalMoney" type="number" :min="0"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="月结金额" prop="monthPayMoney">
            <el-input v-model="ruleForm.monthPayMoney" type="number"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="月结公司" >
            <el-input v-model="ruleForm.monthPayCompany" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {
  getSerachTimeList
} from "@/api/ruge/gsPark/customerService/stadium";
export default {
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("请填写联系电话"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("联系电话格式错误"));
      } else {
        callback();
      }
    };
    return {
      keyValue: 0,
      options: [], // 选择场地下拉数据
      labelPosition: 'top',
      ruleForm: {
        userName: '', // 预定人
        userPhone: '', // 手机号码
        destinedDate: '', //预定时间
        orderType: '', // 预定类型
        siteType: [], // 场地类型和对应时间段
        totalMoney: '', // 人工收费金额
        // 选择时间
        goodsTimeList: [],
        monthPayMoney: '', // 月结金额
        monthPayCompany: '' // 月结公司
      },

      rules: {
        userName: [
          { required: true, message: '请输入预定人名称', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
        ],
        userPhone: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        orderType: [
          { required: true, message: '请选择预定类型', trigger: 'change' },
        ],
        destinedDate: [
          { required: true, message: '请选择预约时间', trigger: 'change' }
        ],
        siteType: [
          { required: true, message: '请选择场地时间段', trigger: 'change' }
        ],
        totalMoney: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { pattern: /^[0-9|^\\.]/, message: '数值不能为负数' },
        ],
        monthPayMoney: [
          { required: false, message: '请输入金额', trigger: 'blur', },
          { pattern: /^[0-9|^\\.]/, message: '数值不能为负数' },
        ],
      }
    }
  },
  created() {
    this.getTimeList()
  },
  methods: {
    getTimeList() {
      // 创建表示当前日期的Date对象
      const currentDate = new Date();
      // 定义年、月、日
      let year
      let month
      let day
      let goodsTimeList = []
      // 打印结果
      for (let i = 0; i < 14; i++) {
        year = currentDate.getFullYear();
        month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        day = ('0' + currentDate.getDate()).slice(-2);
        // console.log(`${year}-${month}-${day}`);
        goodsTimeList[i] = `${year}-${month}-${day}`
        currentDate.setDate(currentDate.getDate() + 1);
      }
      this.ruleForm.goodsTimeList = goodsTimeList
    },
    handelTimeList() {
      // this.options = []
      // console.log('触发了');
      if (this.ruleForm.orderType && this.ruleForm.destinedDate) {
        getSerachTimeList({
          siteType: this.ruleForm.orderType,
          openTime: this.ruleForm.destinedDate
        }).then((res) => {
          // 拿到选择场地的列表数据
          console.log(res.data, 'data');
          let spaceList = []
          res.data.map((item) => {
            let obj = {
              // value: item.siteName,
              value: {
                // id跟场地名称
                siteName: item.siteName,
                goodsId: item.siteId
              },
              label: item.siteName,
            }
            let soltList = []
            item.soltList.map((j, index) => {
              soltList.push({
                value: j.timeSolt,
                label: j.timeSolt,
                disabled: j.isDestined
              })
            })
            obj.children = soltList
            spaceList.push(obj)
          })
          this.options = spaceList
          console.log(this.options, 'this.options');
          // console.log(res.data, 'data');
        })
        // console.log('通过');
      } else {
        // console.log('不通过');
      }
    },
    //
    changeSiteType() {
      // console.log('ruleForm.siteType',this.ruleForm.siteType);
    }
  },
  watch: {
    options(newVal, oldVal) {
      if (newVal) {
        // 处理属性变化
        this.ruleForm.siteType = []
      }
      this.keyValue++
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-select {
  width: 100%;
}

::v-deep .el-cascader--medium {
  width: 100%;
}
::v-deep .el-cascader--medium {
  width: 100%;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
.ChargeRemarks ::v-deep .el-form-item__label:after {
 content: "   人工收费的预定，客户可在小程序查看入场券、可以自助开具电子发票，不可在小程序取消预订";
 font-size:12px;
 color:#909399;
}
.ChargeRemarks ::v-deep .el-form-item__content {
  width:310px;
}
</style>