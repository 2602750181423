import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**
 * 体育场 - 场地预定列表
 * @param params
 */
export function getScheduleList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/queryList',
        method: 'get',
        params,
    });
}

/**
 * 体育场 - 修改状态
 * @param params
 */
export function editInvoiceStatus(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/editInvoiceStatus',
        method: 'get',
        params,
    });
}

/**
 * 体育场 - 场地预定
 * @param params
 */
export function addPredetermine(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/web/create',
        method: 'post',
        data: params,
    });
}

/**
 * 体育场 - 预定详情
 * @param params
 */
export function getComplaintAdviceDetail(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/getDetails',
        method: 'get',
        params,
    });
}

/**
 * 体育场 - 导出功能
 * @param params
 */
export function paymentListExport(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/export',
        method: 'get',
        params,
    });
}

/**
 * 体育场 - 取消订单
 * @param params
 */
export function removeOrder(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/web/cancelOrder',
        method: 'get',
        params,
    });
}

/**
 * 体育场 - 获取场地list
 * @param params
 */
export function getSerachList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/getSiteList',
        method: 'get',
        params,
    });
}


/**
 * 体育场 - 体育预定 获取场地list
 * @param params
 */
export function getSerachTimeList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/destined/getOpenSiteByDateWeb',
        method: 'get',
        params,
    });
}